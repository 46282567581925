@import './misc/perfect-scroll';
@import './misc/rc-tree-select';
@import './misc/estimates-form-table';
@import './misc/estimates-tree-table';

.ntk-table {
  // width: 500px;
  // overflow-x: scroll;
  // margin-left: 15em;
  // overflow-y: visible;
  // padding: 0;
  overflow-x: scroll;

  .headcol {
    // position: absolute;
    // width: 20em;
    // left: 0;
    // top: auto;
    // border-top-width: 1px;
    // margin-top: -1px;
    // background: red;

    position: sticky;
    position: -webkit-sticky;
    background-color: #ffffff;
    width: 300px;
    min-width: 200px;
    max-width: 200px;
    left: 0px;
    z-index: 10;

    &.bg-grey {
      background-color: #d9d9d9;
    }

    &.narrow {
      width: 250px;
      min-width: 150px;
      max-width: 150px;
    }
  }
}

// div[class^="MUIDataTable-responsiveScrollMaxHeight"] {
//   max-height: initial;
// }

.highlight {
  background: #d8d8d8 !important;
}

.MuiInputBase-input {

  &::placeholder {
    color: #000000 !important;
    opacity: 0.8 !important;
  }
}

// If added below - messes up all multiselects - can't see what's selected
// .MuiButtonBase-root.MuiListItem-root.MuiMenuItem-root.Mui-selected.MuiMenuItem-gutters.MuiListItem-gutters.MuiListItem-button.Mui-selected {
//   background-color: #fff !important;

//   &:hover {
//     background-color: rgba(0, 0, 0, 0.08) !important;
//   }
// }

.sticky-table-wrap {
  position: relative;

  .sticky-table-header {
    position: absolute;
    top: 0;
    width: 100%;
    background: #f8fafc;
    z-index: 1150;
  }
}

.text-part-highlight {
  background-color: #dede1c;
}

.MuiTableCell-head {
  vertical-align: top !important;
}

.MuiOutlinedInput-root {
  background-color: #ffffff;
}
/*
.clt,
.clt ul,
.clt li {
  position: relative;
}

.clt ul {
  list-style: none;
  padding-left: 32px;
}

.clt li::before,
.clt li::after {
  content: "";
  position: absolute;
  left: -12px;
}

.clt li::before {
  border-top: 1px solid #000;
  top: 9px;
  width: 8px;
  height: 0;
}

.clt li::after {
  border-left: 1px solid #000;
  height: 100%;
  width: 0px;
  top: 2px;
}

.clt ul>li:last-child::after {
  height: 8px;
}
*/
.norms-tree {

  .tree-node {

    >td {
      border-top: 0px;
      border-bottom: 0px;
      border-right: 1px solid #ddd;
      border-left: 1px solid #ddd;
    }
   
    td {

      &:first-child {
        position: relative;
        padding-left: 10px;

        &::before {
          content: "";
          position: absolute;
          left: 10px;
          border-top: 1px solid #000;
          top: 50%;
          width: 8px;
          height: 0;
        }

        &::after {
          content: "";
          position: absolute;
          left: 10px;
          border-left: 1px solid #000;
          height: 100%;
          width: 0px;
          top: 0px;
        }
      }
    }

    &.level-0 {
      td {
        &:first-child {
          padding-left: 22px;

          &::before {
            display: none;
          }

          &::after {
            display: none;
          }
        }
      }
    }

    // &.level-0 {
    //   td {
    //     &:first-child {
    //       padding-left: 16px;

    //       &::before {
    //         width: 2px;
    //       }
    //     }
    //   }
    // }

    &.level-1 {
      td {
        &:first-child {
          padding-left: 30px;

          &::before {
            width: 16px;
          }
        }
      }
    }

    &.level-2 {
      td {
        &:first-child {
          padding-left: 38px;

          &::before {
            width: 24px;
          }
        }
      }
    }

    &.level-3 {
      td {
        &:first-child {
          padding-left: 46px;

          &::before {
            width: 32px;
          }
        }
      }
    }

    &.level-4 {
      td {
        &:first-child {
          padding-left: 54px;

          &::before {
            width: 40px;
          }
        }
      }
    }

    &.level-5 {
      td {
        &:first-child {
          padding-left: 60px;

          &::before {
            width: 46px;
          }
        }
      }
    }

    &:last-child {
      border-bottom: 1px solid #ddd;
    }
  }

  .tree-node.level-1.level-last {
    td {
      &:first-child {

        &::after {
          // height: 15px;
          height: 50%;
        }
      }
    }
  }

  .tree-details {

    >td {
      border-top: 0px;
      border-bottom: 0px;

      &:first-child {
        position: relative;
        background: #ffffff;

        &::after {
          content: "";
          position: absolute;
          left: 10px;
          border-left: 1px solid #000;
          height: 100%;
          width: 0px;
          top: 0px;
        }
      }
    }
  }
}

.rates-import-table {
  
  td {
    font-weight: normal !important;
  }
}

span.ellipsis {
  display: inline-block;
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  vertical-align: middle;
}

.tox-tinymce {
  z-index: 0 !important;
}

.tree-root {
  // min-height: calc(100vh - 370px);
  font-size: 12px;

  .tree-expander-cell {

    .tree-expander {
      display: inline-block;
      margin-left: 5px;
      margin-right: 5px;
      cursor: pointer;
    }
  
    .tree-cell-value {
      display: inline-block;
    }
  }

  .rdg-cell {
    padding-left: 6px;
    padding-right: 6px;
  }
}

.tree-row-type-estimate {
  &:hover {

    .td {
      background: #b2acfa !important;
    }
  }
  .td {
    background: #b2acfa;
    font-weight: bold;
  }
}

.tree-row-type-chapter {
  &:hover {

    .td {
      background: #c2cfd9 !important;
    }
  }
  .td {
    background: #c2cfd9;
    font-weight: bold;
  }
}

.tree-row-type-chapter {
  &:hover {

    .td {
      background: #dee6ec !important;
    }
  }
  .td {
    background: #dee6ec;
  }
}

.tree-row-type-rate,
.tree-row-type-group {
  .td {
    font-weight: bold;
  }

  .cell-type-title {
    padding-right: 25px !important;
  }
}

.tree-row-type-resource {
  &:hover {

    .td {
      background: italic !important;
    }
  }
  .td {
    font-style: italic;
  }
}

.cellExpandClassname {
  // float: left;
  // display: table;
  // block-size: 100%;
  display: inline-block;
}

.span {
  display: table-cell;
  vertical-align: middle;
  cursor: pointer;
}

.tree-table {
  font-size: 12px;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;

  .td {
    border: 1px solid #f0f0f0;
    padding-left: 5px;
    padding-right: 5px;
  }

  .th {
    background: #fafafa;
    padding: 6px 5px;
    border: 1px solid #f0f0f0;
    font-weight: bold;
  }

  .tr {

    &.highlight {

      .td {
        background: #ef0!important;
      }
    }
  }
}

// .cp_tree-table_cell {

//   .toggle-button {
//     appearance: none;
//     position: relative;
//     font-size: 14px;
//     color: #040402;
//     border: none;
//     padding: 4px 0 4px 15px;
//     background-color: transparent;
//   }
// }

.tree-editable-cell {
  padding-right: 1px;
  text-overflow: ellipsis;
  overflow: hidden;

  input, textarea {
    outline:none;
    border: 0;
    width: 100%;

    &:focus {
      outline: none;
    }
  }

  input {
    background-color: transparent;
  }

  &.is-editing {

    textarea {
      z-index: 999;
      line-height: 16px;
      font-size: 12px;
    }
  }
}

.popout-input {
  // position: absolute !important;
  // top: 0px !important;
  // left: 0px !important;
  // z-index: 99999;
  // background: red;
  // width: 100%;

  textarea {
    // border: 0;
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0px;
    top: 0px;
    resize: none;
  }
}

.documents-create-wrapper {
  position: absolute;
  background: rgba(black, 0.8);
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  z-index: 999;

  .document-dropzone {
    margin: 0 10px;
    height: 90%;
    border: 1px dashed #9E9E9E;
    position: relative;
    top: 50%;
    transform: translateY(-50%);
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;

    &:hover {
      background: rgba(255, 255, 255, 0.2);
    }
  }

  .document-create-close {
    position: absolute;
    top: 5px;
    right: 5px;
    color: white;
  }
}

.documentviewer-filter-wrapper {
  position: absolute;
  bottom: 10px;
  left: 10px;
  width: 300px;
  background: rgba(255, 255, 255, 0.9);
  border-radius: 5px;
  min-height: 300px;
  padding: 5px;
  border: 1px solid #9E9E9E;

  .documentviewer-filter-list {
    list-style: none;
    padding: 0;
    margin: 0;

    li {
      padding: 5px;
      cursor: pointer;

      &:hover {
        color: blue;
      }
    }
  }
}

.documentviewer-selection-wrapper {
  position: absolute;
  bottom: 10px;
  right: 10px;
  width: 330px;
  background: rgba(255, 255, 255, 0.9);
  border-radius: 5px;
  min-height: 300px;
  padding: 5px 5px 43px 5px;
  border: 1px solid #9E9E9E;

  .value-submit {
    position: absolute;
    left: 0px;
    right: 0px;
    bottom: 5px;
    padding: 0px 5px;

    button {
      height: 100%;
    }
  }
}

.edu-link {
  font-weight: bold;
  color: #000000;

  &:hover {
    color: #333333;
  }
}

.edu-notice {
  color: #0967da;
  font-weight: bold;
  font-size: 16px;
  vertical-align: text-bottom;
}

.tree-editable-cell {

  &.readonly {
    cursor: not-allowed;
    font-style: italic;
  }
}

.summary-debug-table {
  table-layout: fixed;

  th {
    background-color: #b2acfa;
  }

  td, th {
    padding: 2px 5px;
    font-size: 12px;
    border: 1px solid #dfe3e7;
    text-align: center !important;
  }

  td {
    background-color: #ffffff;
    font-weight: bold;
  }
}

.tree-expand-all {
  color: #000000;
  display: inline-block;
  vertical-align: top;
}

.td .prefix svg {
  vertical-align: top;
} 

.estimate-summary-table-container,
.estimate-summary-settings-table-container {

  .tree-editable-cell.readonly {
    font-style: normal;
  }

  .tree-row-type-group {
    @extend .tree-row-type-chapter;
  }

  .tree-row-type-item {

  }

  .tree-row-type-virtual {

    .td {
      font-weight: bold;
      color: #FF5722;
    }
  }
}

.estimate-summary-alert {
  background-color: #7d58ff !important;
  color: #ffffff !important;

  svg {
    fill: #ffffff !important;
  }
}